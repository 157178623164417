import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { graphql } from 'gatsby';
import { localizedPath } from 'gatsby-theme-i18n/src/helpers';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import debounce from 'lodash/debounce';

import '../globalStyles.css';
import { ease, generateGradientSteps } from '../helpers';
import Layout from '../components/Layout';

const BlogOverviewContainer = styled.div`
  overflow-x: hidden;
  padding-bottom: 15%;
`;

const BlogPostsContainer = styled.div`
  position: relative;
`;

const Spotlight = styled.header`
  width: 100%;
  height: calc(100vh - 130px);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("${(props) => props.url}");
  position: relative;
  cursor: pointer;

  @media (max-width: 1350px) {
    height: calc(100vh - 150px);
  }

  @media (max-width: 900px) {
    height: calc(100vh - 65px);

    @media (orientation: portrait) {
      height: 60vh;
    }
  }

  @media (max-width: 550px) {
    height: 48vh;
  }
`;

const SpotlightText = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 50px;
  box-sizing: border-box;
  background: linear-gradient(
    0deg,
    ${generateGradientSteps('hsla', [0, 0, 0, 0.8], [0, 0, 0, 0.6], ['', '%', '%', ''], (x) => x, 0, 62, 2)},
    ${generateGradientSteps('hsla', [0, 0, 0, 0.6], [0, 0, 0, 0], ['', '%', '%', ''], ease, 62, 100)}
  );
  color: var(--color-white);

  @media (max-width: 900px) {
    @media (orientation: portrait) {
      padding: 50px 50px 30px 50px;
      background: linear-gradient(
        0deg,
        ${generateGradientSteps('hsla', [0, 0, 0, 0.8], [0, 0, 0, 0.6], ['', '%', '%', ''], (x) => x, 0, 70, 2)},
        ${generateGradientSteps('hsla', [0, 0, 0, 0.6], [0, 0, 0, 0], ['', '%', '%', ''], ease, 70, 100)}
      );
    }
  }

  @media (max-width: 550px), (max-height: 550px) {
    padding: 0 0 15px 0;

    background: linear-gradient(
      0deg,
      ${generateGradientSteps('hsla', [0, 0, 0, 0.8], [0, 0, 0, 0.6], ['', '%', '%', ''], (x) => x, 0, 80, 2)},
      ${generateGradientSteps('hsla', [0, 0, 0, 0.6], [0, 0, 0, 0], ['', '%', '%', ''], ease, 80, 100)}
    );

  }

  & h1 {
    max-width: 50%;
    font-weight: 700;

    @media (max-width: 1350px) {
      font-size: 25px;
      max-width: 60%;
    }

    @media (max-width: 900px){
      max-width: 80%;
      font-size: 23px;

      @media (orientation: portrait) {
        max-width: 80%;
        font-size: 23px;
      }
    }

    @media (max-width: 550px), (max-height: 550px) {
      max-width: 85%;
      margin: auto;
      font-size: 18px;
    }

    @media (max-width: 400px) {
      max-width: 90%;
    }
  }

  & h1:hover, p:last-child:hover {
    color: var(--color-teal);
    cursor: pointer;
  }

  & p {
    max-width: 38%;
    font-size: 17px;

    @media (max-width: 1350px) {
      font-size: 16px;
      max-width: 60%;
    }

    @media (max-width: 900px) {
      max-width: 80%;
      font-size: 15px;

      @media (orientation: portrait) {
        max-width: 80%;
        font-size: 15px;
      }
    }

    @media (max-width: 550px), (max-height: 550px) {
      max-width: 85%;
      padding-bottom: 10px;
      margin: auto;
    }

    @media (max-width: 400px) {
      max-width: 90%;
      font-size: 14px
    }
  }
`;

const SpotlightContainerMobile = styled.div`

`;

const SpotlightDescription = styled.div`
  width: 85%;
  margin: auto;
  font-size: 15px;

  @media (max-width: 400px) {
    width: 90%;
    font-size: 14px;
  }
`;

const BlogItem = styled.article`
  width: 100%;
  margin-top: 200px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:nth-child(odd) {
    flex-direction: row-reverse;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  @media (max-width: 1350px) {
    margin-top: 120px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    width: 100%;
  }

  @media (max-width: 550px), (max-height: 550px) {
    margin-top: 15%;
  }
`;

const BlogItemImageContainer = styled.div`
  width: 50%;
  position: relative;
  transform: translateX(-110%);
  will-change: transform;
  transition: transform 300ms cubic-bezier(0.18, 0.89, 0.51, 1.1);
  transition-delay: 150ms;

  @media (max-width: 900px) {
    transform: none;
    width: 72%;
    margin: 0 50px 0 auto;
  }
  
  @media (max-width: 700px) {
    width: 77%;
    margin: 0 15px 0 auto;
  } 

  @media (max-width: 550px), (max-height: 550px) {
    width: 95%;
    margin: auto;
  }

  @media (max-width: 400px) {
    width: 100%;
  }

  ${BlogItem}:nth-child(odd) & {
    transform: translateX(110%);

    @media (max-width: 900px) {
      transform: none;
    }
  }

  ${BlogItem}.visible & {
    transform: translateX(0%);

    @media (max-width: 900px) {
      transform: none;
    }
  }
`;

const BlogItemImage = styled.div`
  width: 76%;
  margin-right: 24%;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("${(props) => props.url}");

  @media (max-width: 900px) {
    width: 100%;
    margin: auto;
  }

  ${BlogItem}:nth-child(odd) & {
    margin-right: 0;
    margin-left: 24%;

    @media (max-width: 900px) {
      margin: auto;
    }
  }

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 38%;
    height: 100%;
    background:
      linear-gradient(
        -90deg,
        ${generateGradientSteps('hsla', [0, 0, 100, 1], [0, 0, 100, 0], ['', '%', '%', ''])}
      );
    
      @media (max-width: 900px) {
        display: none;
      }
  }

  ${BlogItem}:nth-child(odd) &::after {
    right: auto;
    left: 0;
    background:
      linear-gradient(
        90deg,
        ${generateGradientSteps('hsla', [0, 0, 100, 1], [0, 0, 100, 0], ['', '%', '%', ''])}
      );
  }

  @media (max-width: 1023px) {
    width: 90%;
  }
`;

const BlogItemText = styled.div`
  width: 50%;
  padding: 0 12% 0 4%;
  box-sizing: border-box;
  position: relative;
  will-change: transform;
  transform: translateX(110%);
  transition: transform 300ms cubic-bezier(0.18, 0.89, 0.51, 1.1);
  transition-delay: 150ms;

  @media (max-width: 1350px) {
    font-size: 15px;
    padding: 0 4%;
  }

  @media (max-width: 900px) {
    transform: none;
    width: 100%;
    margin: auto;
  }

  @media (max-width: 550px) {
    padding: 0;
  }

  ${BlogItem}:nth-child(odd) & {
    text-align: right;
    padding: 0 4% 0 12%;
    transform: translateX(-110%);

    @media (max-width: 1350px) {
      padding: 0 4%;
    }

    @media (max-width: 900px) {
      transform: none;
      text-align: left;
    }

    @media (max-width: 550px) {
      padding: 0;
    }
  }

  ${BlogItem}.visible & {
    transform: translateX(0%);
  }

  & h1 {
    margin-top: 0;

    @media (max-width: 900px) {
      width: 70%;
      margin: 0 50px 0 auto;
    }

    @media (max-width: 700px) {
      width: 75%;
      margin: 0 15px 0 auto;
    } 

    @media (max-width: 550px), (max-height: 550px) {
      width: 85%;
      margin: auto;
      font-size: 18px;
    }
  }

  & h1:hover, p:last-child:hover {
    color: var(--color-teal);
    cursor: pointer;
  }

  & h2 {
    font-weight: 700;

    &:hover {
      color: var(--color-teal);
    }

    @media (max-width: 1350px) {
      font-size: 20px;
    }

    @media (max-width: 900px) {
      width: 70%;
      margin: 0 50px 0 auto;
    }

    @media (max-width: 700px) {
      width: 75%;
      margin: 0 15px 0 auto;
    }

    @media (max-width: 550px), (max-height: 550px) {
      width: 85%;
      margin: auto;
      font-size: 18px;
    }

    @media (max-width: 400px) {
      width: 90%;
      font-size: 17px;
    }
  }

  & p {
    @media (max-width: 900px) {
      width: 70%;
      margin: 0 50px 0 auto;
      padding-top: 15px;
    }

    @media (max-width: 700px) {
      width: 75%;
      margin: 0 15px 0 auto;
    }

    @media (max-width: 550px), (max-height: 550px) {
      width: 85%;
      margin: auto;
      font-size: 15px;
    }

    @media (max-width: 400px) {
      width: 90%;
      font-size: 14px;
    }
  }
  
`;

const PaddingHack = styled.div`
  width: 100%;
  height: 0;
  padding-top: 56.25%;
`;

const Timeline = styled.div`
  position: absolute;
  top: calc(100vh - 130px);
  left: 50%;
  bottom: 0;
  width: 1px;
  background-color: var(--color-dark-grey);

  @media (max-width: 1350px) {
    top: calc(100vh - 150px);
  }

  @media (max-width: 900px) {
    left: 20px;

    @media (orientation: portrait) {
      top: 60vh;
      bottom: -25px;
    }
  }
`;

const TimelineSpot = styled.div`
  position: absolute;
  width: 9px;
  height: 9px;
  left: -4px;
  top: 0;
  transform: translateY(50%);
  box-sizing: border-box;
  background-color: var(--color-dark-grey);
  border: 1px solid var(--color-dark-grey);
  border-radius: 50%;

  @media (max-width: 900px) {
    left: 16px;
    top: 15px;
  }

  ${BlogItem}:nth-child(odd) & {
    left: auto;
    right: -5px;

    @media (max-width: 900px) {
      left: 16px;
      right: 0;
    }
  }

  ${BlogItem}:nth-child(odd) &::before {
    right: auto;
    left: -2.5vw;

    @media (max-width: 900px) {
      left: 8px;
    }
  }

  &::before {
    content: ' ';
    position: absolute;
    width: 2.5vw;
    height: 1px;
    top: 3px;
    right: -2.5vw;
    background-color: var(--color-dark-grey);

    @media (max-width: 900px) {
      right: auto;
      left: 8px;
    }
  }
`;

const DateText = styled.p`
  color: var(--color-dark-grey);
  font-weight: 500;

  ${BlogItem} & {
    margin: 0 0 8% 0;

    @media (max-width: 900px) {
      margin: 0 0 1% 0;
    }

    @media (max-width: 550px), (max-height: 550px) {
      margin: auto;
    }
  }

  ${SpotlightText} & {
    color: var(--color-white);
    padding-left: 0;
  }

  @media (max-width: 900px) {
    width: 100%;
    padding-left: 3vw;
  }

  @media (max-width: 700px) {
    padding-left: 3.5vw;
  }

  @media (max-width: 550px), (max-height: 550px) {
    width: 85%;
    padding: 3% 0 1% 0;
    font-size: 15px;
  }

  @media (max-width: 400px) {
    width: 90%;
    font-size: 14px;
  }
`;

const UpButton = styled.button`
  background-color: var(--color-teal);
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: calc(100vh - 45px - 45px);
  right: 45px;
  z-index: 1;
  transition: none;

  &:hover {
    background-size: 100% 0px, 100% 0px;
  }

  &::before, ::after, button:hover::before, button:hover::after {
    display: none;
  }

  @media (max-width: 1920px) {
    top: calc(100vh - 45px - 35px);
    right: 35px;
  }

  @media (max-width: 1023px) {
  top: calc(100vh - 45px - 115px);
  right: 0;
  }

`;

const LinkToBlogPost = styled.a`
  color: var(--color-dark-grey);
  text-decoration: none; 
`;

export default class Home extends Component {
  static propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
    isPreview: PropTypes.bool,
  };

  constructor(...params) {
    super(...params);
    this.state = {
      showButton: false,
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.onResize);

      window.setTimeout(this.onResize, 100);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
      if (this.resizeTimeout) {
        window.clearTimeout(this.resizeTimeout);
        this.resizeTimeout = null;
      }
    }
    if (this.scrollableLayout) {
      this.scrollableLayout.removeEventListener('scroll', () => this.onScroll());
    }
  }

  onResize = () => {
    if (typeof window !== 'undefined') {
      if (this.resizeTimeout) {
        window.clearTimeout(this.resizeTimeout);
        this.resizeTimeout = null;
      }
      this.resizeTimeout = window.setTimeout(() => {
        this.forceUpdate();
      }, 500);
    }
  };

  intersectionObserver = null;

  addToObserver = [];

  renderBlogPost = (post, index) => {
    const { pageContext, data } = this.props;
    const readMore = data?.mdx?.frontmatter?.readMore || '';
    const id = post?.node?.id || Math.random();
    const title = post?.node?.frontmatter?.title || 'TITLE NOT FOUND';
    const description = post?.node?.frontmatter?.description || 'DESCRIPTION NOT FOUND';
    const publishDate = post?.node?.frontmatter?.publishDate || Date.now();
    const image = post?.node?.frontmatter?.image || '';

    const date = post?.node?.frontmatter?.publishDate;
    const formattedDate = moment(date).utcOffset(0).format('YYYY-MM-DD-HH-mm-ss');

    if (index === 0 && (typeof window === 'undefined' || window.innerWidth > 550)) {
      return (
        <LinkToBlogPost
          href={
            localizedPath({
              locale: pageContext.locale,
              path: `/blog/${formattedDate}/`,
              defaultLang: 'de',
              prefixDefault: false,
            })
          }
          key={id}
        >
          <Spotlight
            id="blog-overview-header"
            url={image}
            key={id}
            tabIndex="0"
          >
            <SpotlightText>
              <DateText>{moment(publishDate).format(pageContext.dateFormat)}</DateText>
              <h1>{title}</h1>
              <p>{description}</p>
              <p>
                {readMore}
                &nbsp;&#187;
              </p>
            </SpotlightText>
          </Spotlight>
        </LinkToBlogPost>
      );
    }

    if (index === 0 && typeof window !== 'undefined' && window.innerWidth <= 550) {
      return (
        <LinkToBlogPost
          href={
            localizedPath({
              locale: pageContext.locale,
              path: `/blog/${formattedDate}/`,
              defaultLang: 'de',
              prefixDefault: false,
            })
          }
          key={id}
        >
          <SpotlightContainerMobile
            id="blog-overview-header"
            key={id}
            tabIndex="0"
          >
            <Spotlight
              url={image}
            >
              <SpotlightText>
                <DateText>{moment(publishDate).format(pageContext.dateFormat)}</DateText>
                <h1>{title}</h1>
              </SpotlightText>
            </Spotlight>
            <SpotlightDescription>
              <p>{description}</p>
              <p>
                {readMore}
                &nbsp;&#187;
              </p>
            </SpotlightDescription>
          </SpotlightContainerMobile>
        </LinkToBlogPost>
      );
    }

    return (
      <BlogItem
        ref={(el) => {
          if (el) {
            if (this.intersectionObserver) {
              this.intersectionObserver.observe(el);
            } else {
              this.addToObserver.push(el);
            }
            // TODO: make sure each page is only observed once
          }
        }}
        key={id}
        tabIndex="0"
      >
        <BlogItemImageContainer>
          <LinkToBlogPost href={
            localizedPath({
              locale: pageContext.locale,
              path: `/blog/${formattedDate}/`,
              defaultLang: 'de',
              prefixDefault: false,
            })
          }
          >
            <BlogItemImage url={image}>
              <PaddingHack />
            </BlogItemImage>
          </LinkToBlogPost>
        </BlogItemImageContainer>
        <BlogItemText>
          <LinkToBlogPost href={
            localizedPath({
              locale: pageContext.locale,
              path: `/blog/${formattedDate}/`,
              defaultLang: 'de',
              prefixDefault: false,
            })
          }
          >
            <DateText>{moment(publishDate).format(pageContext.dateFormat)}</DateText>
            { typeof window !== 'undefined' && window.innerWidth > 550 && window.innerHeight > 550 && (
              <TimelineSpot />
            )}
            <h2>{title}</h2>
            <p>{description}</p>
            <p>
              {readMore}
              &nbsp;&#187;
            </p>
          </LinkToBlogPost>
        </BlogItemText>
      </BlogItem>
    );
  };

  getScrollableParent = (el) => {
    if (!el) return null;
    if (el.classList.contains('scrollable')) {
      return el;
    }
    return this.getScrollableParent(el.parentElement);
  };

  onScroll = debounce(() => {
    const { showButton } = this.state;
    const shouldShowButton = this.scrollableLayout.scrollTop > 4000;
    if (shouldShowButton) {
      if (!showButton) {
        this.setState({ showButton: true });
      }
    } else if (showButton) {
      this.setState({ showButton: false });
    }
  }, 100);

  renderContainer = () => {
    const { showButton } = this.state;
    const { data, isPreview } = this.props;
    const allBlogPosts = data?.allMdx?.edges || [];
    const blogPosts = isPreview ? allBlogPosts : allBlogPosts.filter((post) => new Date(post?.node?.frontmatter?.publishDate).getTime() <= Date.now());
    blogPosts.sort((a, b) => b?.node?.frontmatter?.publishDate.localeCompare(a?.node?.frontmatter?.publishDate));

    const variantMatch = typeof window !== 'undefined' && window.location.search.match(/var=([0-9]+)/i);
    const variant = variantMatch ? parseInt(variantMatch[1], 10) : 0;

    return (
      <BlogOverviewContainer
        tabIndex="-1"
        ref={(el) => {
          if (el) {
            const root = this.getScrollableParent(el) || el;
            const options = {
              root,
              rootMargin: '0px',
              threshold: 0.25,
            };
            this.scrollableLayout = root;
            this.scrollableLayout.addEventListener('scroll', this.onScroll);

            this.intersectionObserver = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  entry.target.classList.add('visible');
                }
              });
            }, options);
            if (this.addToObserver.length) {
              this.addToObserver.forEach((child) => this.intersectionObserver.observe(child));
            }
          }
        }}
      >
        <BlogPostsContainer>
          { typeof window !== 'undefined' && window.innerWidth > 550 && window.innerHeight > 550 && (
            <Timeline />
          )}
          { blogPosts.map((post, i) => this.renderBlogPost(post, i, variant)) }
          { showButton && (
            <UpButton
              type="button"
              onClick={() => {
                document.getElementById('blog-overview-header').scrollIntoView();
                if (typeof window !== 'undefined') {
                  window.setTimeout(() => {
                    document.body.scrollIntoView();
                  }, 100);
                }
              }}
            >
              <img src="/img/arrow_up_white.svg" alt="arrow up" />
            </UpButton>
          )}
        </BlogPostsContainer>
      </BlogOverviewContainer>
    );
  };

  render() {
    const { data, pageContext, isPreview } = this.props;

    let canonicalLinkPrefix;

    if (pageContext.locale === 'en') {
      canonicalLinkPrefix = 'https://presono.com/en';
    } else {
      canonicalLinkPrefix = 'https://presono.com';
    }

    return isPreview ? this.renderContainer() : (
      <Layout currentPath={pageContext.originalPath}>
        <Helmet>
          <title>{`${data?.mdx?.frontmatter?.title} | presono`}</title>
          <meta
            name="description"
            content={data?.mdx?.frontmatter?.snippetTextBlog}
          />

          <link rel="canonical" href={canonicalLinkPrefix + pageContext.originalPath} />

          <meta property="og:title" content={data?.mdx?.frontmatter?.title} />
          <meta property="og:description" content={data?.mdx?.frontmatter?.snippetTextBlog} />
          <meta name="twitter:title" content={data?.mdx?.frontmatter?.title} />
          <meta name="twitter:description" content={data?.mdx?.frontmatter?.snippetTextBlog} />
        </Helmet>
        { typeof window !== 'undefined' && this.renderContainer() }
      </Layout>
    );
  }
}

export const query = graphql`
query BlogQuery($locale: String!) {
  mdx(
    fields: { locale: { eq: $locale } }
    frontmatter: { layout: { eq: "blog" } }
  ) {
    frontmatter {
      title
      snippetTextBlog
      readMore
    }
  }
  allMdx(
    sort: { order: DESC, fields: [frontmatter___publishDate] }
    filter: {
      fields: { locale: { eq: $locale } }
      frontmatter: { layout: { eq: "blog-post" } }
    }
  ) {
    edges {
      node {
        frontmatter {
          publishDate
          description
          title
          image
        }
        id
        slug
      }
    }
  }
}
`;
